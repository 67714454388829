import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const cardStyle = {
  card: {
    width: "200px",
    height: "260px",
    marginTop: "10px",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  cardImage: {
    width: "200px",
    height: "150px",
  },
  cardTitle: {
    fontSize: "17px",
  },
  cardLink: {
    color: "#009ffd",
    position: "absolute",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const SingleCard = ({ item, currentCategory }) => {
  return (
    <>
      <Card style={cardStyle.card}>
        <Card.Img
          className=" hover-shadow"
          style={cardStyle.cardImage}
          variant="top"
          src={item.Thumbnail}
        />
        <Card.Body>
          <Card.Title style={cardStyle.cardTitle}>{item.Title}</Card.Title>
          <Link
            to={`${currentCategory.Path}/${item.Id}`}
            style={cardStyle.cardLink}
          >
            Detalii
          </Link>
        </Card.Body>
      </Card>
    </>
  );
};

export default SingleCard;
