import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Components/Navbar";
import Views from "./Components/Views";
import Radium, { StyleRoot } from "radium";
import Footer from "./Components/Footer";
import styled from "styled-components";

const Content = styled.div`
  margin-bottom: 250px;

  @media (max-width: 600px) {
    margin-bottom: 320px;
  }
`;

function App() {
  const [sideNav, setSideNav] = useState(false);
  const showSideNav = () => {
    setSideNav(!sideNav);
  };

  // ---------------------------------Datele categoriilor se retin in format json in variabila categoryData--------------------------------
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("categoryData.php");
      const categories = await response.json();
      setCategoryData(categories);
    };
    fetchData();

    // fetch("categoryData.php")
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((result) => setCategoryData(result));
  }, [categoryData]);
  //console.log(categoryData);

  // ---------------------------------Datele cardurilor(caselor) se retin in format json in variabila cardsData--------------------------------
  const [cardsData, setCardsData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("cardsData.php");
      const cards = await response.json();
      setCardsData(cards);
    };
    fetchData();
    // fetch("cardsData.php")
    //   .then((response) => {
    //     return response.json();
    //   })
    //   // .then((response) => {
    //   //   console.log(response)
    //   // })
    //   .then((result) => setCardsData(result));
  }, [cardsData]);
  //console.log(cardsData);

  // ---------------------------------Datele categoriilor se retin in format json in variabila imagesData--------------------------------
  const [imagesData, setImagesData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("imagesData.php");
      const images = await response.json();
      setImagesData(images);
    };
    fetchData();
    // fetch("imagesData.php")
    //   .then((response) => {
    //     return response.json();
    //   })
    //   // .then((response) => {
    //   //   console.log(response)
    //   // })
    //   .then((result) => setImagesData(result));
  }, [imagesData]);
  //console.log(imagesData);

  //-----------------Find current card------------------

  const findCurrentCard = (id) => {
    if (cardsData) {
      const currCard = cardsData.find((item) => {
        return parseInt(item.Id) == parseInt(id);
      });

      return currCard;
    }
  };

  //------------Find current card images----------------

  const findCurrentCardGalleryImages = (id) => {
    if (imagesData) {
      const currCardGalleryImages = imagesData.filter((item) => {
        return item.CardId == id;
      });
      return currCardGalleryImages;
    }
  };
  return (
    <>
      {cardsData.length != 0 &&
        categoryData.length != 0 &&
        imagesData.length != 0 && (
          <StyleRoot>
            <div
              className="d-flex flex-column "
              style={{ minHeight: "100vh", position: "relative" }}
            >
              <Content>
                <Navbar
                  sideNav={sideNav}
                  showSideNav={showSideNav}
                  categoryData={categoryData}
                />
                <Views
                  cardsData={cardsData}
                  categoryData={categoryData}
                  imagesData={imagesData}
                  //currentCard={currentCard}
                  findCurrentCard={findCurrentCard}
                  findCurrentCardGalleryImages={findCurrentCardGalleryImages}
                  //currentCardGalleryImages={currentCardGalleryImages}
                />
              </Content>
              <Footer />
            </div>
          </StyleRoot>
        )}
    </>
  );
}

export default App;
