import React from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import SideNav from "./SideNav";

const Nav = styled.nav`
  background: #000;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem calc((100vw-1000px) / 2);
  z-index: 10;
  position: relative;
`;

const NavMenu = styled.nav`
  @media screen and (max-width: 1220px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  cursor: pointer;
  color: white;
  font-size: 13px;

  &.active {
    color: rgba(102, 123, 207, 255);
  }
  &:hover {
    color: rgba(102, 123, 207, 255);
  }
  @media screen and (min-width: 1300px) {
    font-size: 15px;
  }
`;

const HambourgerIcon = styled.div`
  color: white;
  position: absolute;
  right: 20px;
  font-size: 30px;
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  @media screen and (max-width: 1220px) {
    display: block;
  }
`;
const NavStyle = {
  LogoImage: {
    width: "28px",
    height: "28px",
  },
  Logo: {
    color: "rgba(102,123,207,255)",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "10px",
    top: "50%",
    zIndex: "100",
    transform: "translateY(-50%)",
  },
};
const Navbar = ({ showSideNav, sideNav, categoryData }) => {
  return (
    <div>
      <Nav>
        {/* ----------------------------------------Logo-ul--------------------------------------------------- */}

        <NavLink to="/" style={NavStyle.Logo}>
          <img src="/images/basc-logo.jpg" style={NavStyle.LogoImage} />
          <div className="ms-2">BASC</div>
        </NavLink>

        {/* ---------------------------------Meniul pe larg--------------------------------- */}
        <NavMenu>
          <NavLink to="/">Home</NavLink>

          {categoryData.map((item) => {
            return (
              <NavLink to={item.Path} key={item.Id}>
                {item.Name}
              </NavLink>
            );
          })}
          <NavLink to="/Certificari">Certificări</NavLink>
        </NavMenu>

        {/* ---------------------------------Meniul pe telefon ca Hambourger Icon--------------------------------- */}
        {!sideNav && (
          <HambourgerIcon onClick={showSideNav}>
            <FaBars style={NavStyle.HambourgerIcon} />
          </HambourgerIcon>
        )}
        {/* ---------------------------------Meniul lateral pe telefon--------------------------------- */}

        {sideNav && <SideNav sideNav={sideNav} showSideNav={showSideNav} />}
      </Nav>
    </div>
  );
};

export default Navbar;
