import React from "react";
import * as AiIcons from "react-icons/ai";
import styled from "styled-components";
import SocialMediaBar from "./SocialMediaBar";
import SocialMediaLucy from "./SocialMediaLucy";

const FooterWrap = styled.div`
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  background-color: rgba(237, 231, 225, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 80%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    margin-right: 20px;
  }
  @media (min-width: 1200px) {
    margin-right: 100px;
  }
`;
const ContactContainer = styled.div`
  padding: 10px;
  @media (min-width: 768px) {
    margin-left: 20px;
  }
  @media (min-width: 1200px) {
    margin-left: 100px;
  }
`;
const Blackline = styled.div`
  width: 100%;
  background-color: black;
  color: white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const stil = {
  logoImage: {
    width: "60px",
  },
};
const Footer = () => {
  return (
    <div style={{ position: "absolute", width: "100%", bottom: "0" }}>
      <FooterWrap>
        <FooterContent>
          <LogoContainer md="auto">
            <img src="/images/basc-logo.jpg" style={stil.logoImage} />
            <div className="ms-2">
              <h6>
                <span>Arhitect </span>
              </h6>
              <div className=" fw-bold">Ciprian Șimon </div>
            </div>
          </LogoContainer>

          <ContactContainer md="auto">
            <div>
              <div className="mt-2 d-flex  align-items-center">
                <AiIcons.AiOutlinePhone className="me-1 " />
                <div className="fw-bold me-1">Telefon mobil : </div> 0723-769462
              </div>
              <div className="mt-2  d-flex  align-items-center">
                <AiIcons.AiOutlineMail className="me-1 " />
                <div className="fw-bold me-1">Email :</div>
                arhsimonciprian@gmail.com
              </div>
              <div className="mt-2">
                <SocialMediaBar />
              </div>
            </div>
          </ContactContainer>
        </FooterContent>
      </FooterWrap>
      <Blackline>
        <div>React site powered by Suciu Luciana</div>
        <SocialMediaLucy />
      </Blackline>
    </div>
  );
};

export default Footer;
