import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import ImageGallery from "./ImageGallery";

const CardDetails = ({
  cardsData,
  imagesData,
  currentCategory,
  //currentCard,
  findCurrentCard,
  findCurrentCardGalleryImages,
  //currentCardGalleryImages,
}) => {
  console.log(cardsData);
  const params = useParams();
  const cardId = params.CardId;
  const [currentCard, setCurrentCard] = useState();
  const [currentCardGalleryImages, setCurrentCardGalleryImages] = useState([]);

  useEffect(() => {
    setCurrentCard(findCurrentCard(cardId));
  }, [cardsData]);

  useEffect(() => {
    setCurrentCardGalleryImages(findCurrentCardGalleryImages(cardId));
  }, [imagesData]);

  const cardDetailsStyle = {
    CategoryTitle: {
      fontSize: "30px",
    },
    CardTitle: {
      fontSize: "20px",
    },
    WrapContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    DescriptionContainer: {
      textAlign: "center",
      marginTop: "40px",
    },
  };

  return (
    <>
      {currentCard && currentCategory && currentCardGalleryImages && (
        <Container style={cardDetailsStyle.WrapContainer}>
          <Container style={cardDetailsStyle.DescriptionContainer}>
            <div style={cardDetailsStyle.CategoryTitle}>
              -{currentCategory.Name}-
            </div>
            <div style={cardDetailsStyle.CardTitle}>{currentCard.Title}</div>
          </Container>
          <ImageGallery galleryImages={currentCardGalleryImages} />
        </Container>
      )}
    </>
  );
};

export default CardDetails;
