import React from "react";
import styled from "styled-components";

const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FacebookIcon = styled.a`
  color: #4267b2;
  margin-left: 15px;
  display: flex;
`;

const WhatsappIcon = styled.a`
  margin-left: 15px;
`;

const SocialMediaLucy = () => {
  return (
    <SocialMediaContainer>
      <FacebookIcon
        href="https://www.facebook.com/badea.vasile2012/"
        target="_blank"
      >
        <img
          src="/images/social-media-icons/facebook.png"
          style={{ height: "18px", width: "18px" }}
        />
      </FacebookIcon>

      <WhatsappIcon
        href="https://wa.me/+40757761615"
        className="d-flex"
        style={{ color: "#25D366" }}
        target="_blank"
      >
        <img
          src="/images/social-media-icons/whatsapp.png"
          style={{ height: "18px", width: "18px" }}
        />
      </WhatsappIcon>
    </SocialMediaContainer>
  );
};

export default SocialMediaLucy;
