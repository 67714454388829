import React, { useRef, useEffect } from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import * as AiIcons from "react-icons/ai";

const FullPageWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  ${({ sideNav }) => (!sideNav ? "left:100%;" : "right:0;")}
  transition:750ms;
`;

const SideWrap = styled.div`
  background-color: rgba(237, 231, 225, 0.7);
  width: 130px;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 60px;
  display: flex;
  flex-direction: column;
`;
const NavLink = styled(Link)`
  text-decoration: none;
  padding: 0.4rem;
  cursor: pointer;
  color: black;
  margin-left: 10px;

  &.active {
    color: rgba(102, 123, 207, 255);
  }
  &:hover {
    color: rgba(102, 123, 207, 255);
  }
`;

const NavIconClose = styled.div`
  font-size: 2em;
  color: black;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 10px;
`;
const SideNav = ({ showSideNav, sideNav }) => {
  return (
    <>
      <FullPageWrap sideNav={sideNav}>
        <SideWrap>
          {/* -------------X-ul de inchidere a side nav---------------- */}
          <NavIconClose onClick={showSideNav} to="#">
            <AiIcons.AiOutlineClose />
          </NavIconClose>
          {/* -------------Meniul din laterala ---------------- */}
          <NavLink onClick={showSideNav} to="/">
            Home
          </NavLink>
          <NavLink onClick={showSideNav} to="/Case">
            Case
          </NavLink>
          <NavLink onClick={showSideNav} to="/Blocuri">
            Blocuri
          </NavLink>
          <NavLink onClick={showSideNav} to="/Industrial">
            Industrial
          </NavLink>
          <NavLink onClick={showSideNav} to="/Turism">
            Turism
          </NavLink>
          <NavLink onClick={showSideNav} to="/Birouri">
            Birouri
          </NavLink>
          <NavLink onClick={showSideNav} to="/Biserici">
            Biserici
          </NavLink>
          <NavLink onClick={showSideNav} to="/Publice">
            Publice
          </NavLink>
          <NavLink onClick={showSideNav} to="/Urbanism">
            Urbanism
          </NavLink>
          <NavLink onClick={showSideNav} to="/Amenajari">
            Amenajări
          </NavLink>
          <NavLink onClick={showSideNav} to="/Expozitii">
            Expoziții
          </NavLink>
          <NavLink onClick={showSideNav} to="/Schite">
            Schițe
          </NavLink>
          <NavLink onClick={showSideNav} to="/Concursuri">
            Concursuri
          </NavLink>
          <NavLink onClick={showSideNav} to="/Certificari">
            Certificări
          </NavLink>
        </SideWrap>
      </FullPageWrap>
    </>
  );
};

export default SideNav;
