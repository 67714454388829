import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import ImageGallery from "../Components/ImageGallery";
import { NavLink as Link } from "react-router-dom";
import Radium, { StyleRoot } from "radium";

const Wrap = styled.div`
  width: 100%;
`;
const TextContainer = styled(Container)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  padding: 30px;
  background-color: white;
  margin-bottom: 30px;
  width: 90%;
`;
const HomeHeader = styled(Container)`
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

const HomeText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
  text-align: center;
`;

const HeaderTextBigScreen = styled.div`
  width: 80%;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
const HeaderTextSmallScreen = styled.div`
  padding: 30px;
  font-weight: 600;
  // border: 2px solid blue;
  color: rgba(0, 0, 0, 0.8);

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;
const Informatii = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
const InformatiiPersonale = styled.div`
  margin-right: 50px;
  padding: 10px;
`;
const DateFiscale = styled.div`
  padding: 10px;
`;
const NavLink = styled(Link)``;
const Logo = styled.div``;

const CipriPicture = styled.div``;

const style = {
  LogoImage: {
    height: "150px",
    "@media (min-width: 1000px)": {
      width: "150px",
      height: "150px",
    },
    "@media (max-width: 999px)": {
      width: "100px",
      height: "100px",
    },
  },
  CipriPicture: {
    height: "150px",
    "@media (min-width: 1000px)": {
      width: "150px",
      height: "150px",
    },
    "@media (max-width: 999px)": {
      width: "100px",
      height: "100px",
    },
  },
  HomePicture: {
    width: "60%",
  },
};
const Certificari = ({ imagesData }) => {
  const [imagesFromCategoryCertificări, setImagesFromCategoryCertificări] =
    useState([]);
  // in DB imaginile din actegoria Certificari au CategoryId=0
  useEffect(() => {
    const images = imagesData.filter((item) => {
      return parseInt(item.CategoryId) == 0;
    });
    setImagesFromCategoryCertificări(images);
  }, [imagesData]);

  return (
    <StyleRoot>
      {/* ---------------------------------Header-ul------------------------ */}
      <HomeHeader>
        <Logo>
          <NavLink to="/home">
            <img src="/images/basc-logo.jpg" style={style.LogoImage} />
          </NavLink>
        </Logo>
        <HomeText>
          <Title>Birou de arhitectură Șimon Ciprian</Title>
          <HeaderTextBigScreen>
            <div>
              Cred că arhitectura și urbanismul vor avea mereu la bază cele trei
              vechi principii: utilitate, trăinicie și frumusețe.
            </div>
            <div>
              Ponderea lor, în măsuri variabile, este rezultatul colaborării
              dintre arhitect, beneficiar și comunitatea în care trăiesc.
            </div>
            <div>
              Toată munca arhitectului trebuie îndreptată către găsirea
              echilibrului între util, trainic și frumos , pentru a ni se
              descoperi nouă tuturor cât mai multe din bucuriile esențiale ale
              vieții.
            </div>
          </HeaderTextBigScreen>
        </HomeText>
        <CipriPicture>
          <img src="/images/poza-Cipri.jpg" style={style.CipriPicture} />
        </CipriPicture>
      </HomeHeader>
      <HeaderTextSmallScreen>
        <div>
          Cred că arhitectura și urbanismul vor avea mereu la bază cele trei
          vechi principii: utilitate, trăinicie și frumusețe.
        </div>
        <div>
          Ponderea lor, în măsuri variabile, este rezultatul colaborării dintre
          arhitect, beneficiar și comunitatea în care trăiesc.
        </div>
        <div>
          Toată munca arhitectului trebuie îndreptată către găsirea echilibrului
          între util, trainic și frumos , pentru a ni se descoperi nouă tuturor
          cât mai multe din bucuriile esențiale ale vieții.
        </div>
      </HeaderTextSmallScreen>
      <Wrap>
        <TextContainer>
          <Informatii>
            <InformatiiPersonale>
              <h5>
                <span>INFORMAŢII DE CONTACT:</span>
              </h5>
              <div>
                <div>Adresa: Cluj-Napoca, str. 13 Septembrie nr. 2 , ap. 7</div>
                <div>Telefon: +40723769462</div>
              </div>
            </InformatiiPersonale>
            <DateFiscale>
              <h5>DATE FISCALE</h5>
              <div>
                <div>Birou de arhitectură Șimon Ciprian SRL</div>
                <div>Jud. Cluj , Comuna Baciu , sat Popești nr. 148i</div>
                <div>J12/6846/2017</div>
                <div>CUI 38624605</div>
                <div>C.I.: CJ 339141</div>
                <div>
                  Cont: RO50 BTRL RONC RT04 2827 8201 , Banca Transilvania CJ
                </div>
              </div>
            </DateFiscale>
          </Informatii>

          <Container className="">
            <div className="d-flex justify-content-center  mt-5">
              <ul>
                <li>
                  20 ani de experienţă în proiectare de arhitectură şi urbanism,
                  inclusiv proiecte realizate şi aprobate pe fonduri europene
                </li>
                <li>
                  Membru al Ordinului Arhitecţilor din România din anul 2002
                </li>
                <li>
                  Membru al Registrului Urbaniştilor din România din anul 2007
                  (certificat RUR categoria D şi E – PUZ, PUD, PUG)
                </li>
              </ul>
            </div>
            <ImageGallery galleryImages={imagesFromCategoryCertificări} />
          </Container>
        </TextContainer>
      </Wrap>
    </StyleRoot>
  );
};

export default Certificari;
