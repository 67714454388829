import React from "react";
import { Container, Row } from "react-bootstrap";
import Radium, { StyleRoot } from "radium";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

// const CardRow = styled.div`
//   @media screen and (max-width: 500px) {
//     width: 80%;
//   }
//   @media screen and (min-width: 501px) {
//     width: 50%;
//   }
// `;

const componentHeaderStyle = {
  CardRow: {
    "@media (max-width: 500px)": {
      width: "80%",
    },
    "@media (min-width: 501px)": {
      width: "50%",
    },
  },
  HeaderImage: {
    "@media (min-width: 1000px)": {
      width: "45%",
    },
    "@media (max-width: 999px)": {
      width: "500px",
    },
  },

  HeaderCard: {
    marginTop: "-20px",
    padding: "5px",
    "@media (max-width: 500px)": {
      width: "30%",
      color: "red",
    },
    "@media (min-width: 501x)": {
      width: "80%",
      color: "green",
    },
  },
  ImgRow: {
    display: "flex",
    justifyContent: "center",
  },
  Title: {
    fontWeight: "700",
    padding: "5px",
    textAlign: "center",
    "@media (min-width: 1000px)": {
      fontSize: "22px",
    },
    "@media (max-width: 999px)": {
      fontSize: "18px",
    },
  },
  Text: {
    "@media (min-width: 1000px)": {
      fontSize: "18px",
    },
    "@media (max-width: 999px)": {
      fontSize: "16px",
    },
  },
};

const ComponentHeader = ({ category }) => {
  return (
    <>
      {category && (
        <StyleRoot>
          <Container fluid>
            <Row style={componentHeaderStyle.ImgRow}>
              <img
                src={category.HeaderImage}
                style={componentHeaderStyle.HeaderImage}
              />
            </Row>
            <Row className="d-flex justify-content-center">
              <div style={componentHeaderStyle.CardRow}>
                <Card style={componentHeaderStyle.HeaderCard}>
                  <Card.Body>
                    <div style={componentHeaderStyle.Title}>
                      {category.CardTitle}
                    </div>
                    <div style={componentHeaderStyle.Text}>
                      {category.CardText}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Row>
          </Container>
        </StyleRoot>
      )}
    </>
  );
};

export default ComponentHeader;
