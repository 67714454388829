import React, { useState } from "react";
import * as MdIcons from "react-icons/md";
import { Container, Row } from "react-bootstrap";

const ImageGallery = ({ galleryImages }) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  //  ****************************
  //  HANDLE MODAL
  //  ****************************
  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  //  ****************************
  //  IMAGINE ANTERIOARA DIN MODAL
  //  ****************************
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  //  ****************************
  //  INCHIDEREA MODALULUI
  //  ****************************
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //  ****************************
  //  IMAGINEA URMATOARE DIN MODAL
  //  ****************************
  const nextSlide = () => {
    slideNumber === galleryImages.length - 1
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  const ImageGalleryStyle = {
    galleryWrap: {
      display: "flex",
      flexGrow: "1",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      height: "50%",
      gap: "0px",
      margin: "0px auto",
    },
    single: {
      cursor: "pointer",
      // border: '1px solid grey',
      padding: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "240px",
      width: "220px",
    },
    image: {
      maxWidth: "200px",
      maxHeight: "200px",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },

    slider: {
      fontSize: "30px",
    },

    sliderWrap: {
      width: "100%",
      height: "auto",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    btnPrev: {
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
    btnClose: {
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
    btnNext: {
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
  };

  return (
    <div>
      {/* ********************
                MODAL
      ******************** */}
      {openModal && (
        <Container style={ImageGalleryStyle.sliderWrap}>
          <Row className="text-center">
            <div>{galleryImages[slideNumber].Description}</div>
            <img src={galleryImages[slideNumber].Path} alt="imagine modal" />
          </Row>

          <div style={ImageGalleryStyle.slider}>
            <MdIcons.MdSkipPrevious
              style={ImageGalleryStyle.btnPrev}
              onClick={prevSlide}
            />
            <MdIcons.MdOutlineClose
              style={ImageGalleryStyle.btnClose}
              onClick={handleCloseModal}
            />
            <MdIcons.MdSkipNext
              style={ImageGalleryStyle.btnNext}
              onClick={nextSlide}
            />
          </div>
        </Container>
      )}

      {/* ********************
          GALERIE IMAGINI
      ******************** */}
      <div style={ImageGalleryStyle.galleryWrap}>
        {galleryImages.map((item, index) => {
          return (
            <div
              style={ImageGalleryStyle.single}
              key={index}
              onClick={() => handleOpenModal(index)}
            >
              <div className="mb-2">{item.Description}</div>
              <img
                src={item.Path}
                alt="imagine galerie"
                style={ImageGalleryStyle.image}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageGallery;
