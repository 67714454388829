import React from "react";
import styled from "styled-components";

const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FacebookIcon = styled.a`
  color: #4267b2;
  font-size: 10px;
  margin-left: 15px;
  display: flex;
`;

const WhatsappIcon = styled.a`
  font-size: 30px;
  margin-left: 15px;
`;

const SocialMediaBar = () => {
  return (
    <SocialMediaContainer>
      <FacebookIcon
        href="https://www.facebook.com/profile.php?id=100011587295138"
        target="_blank"
      >
        <img
          src="/images/social-media-icons/facebook.png"
          style={{ height: "25px", width: "25px" }}
        />
      </FacebookIcon>

      <WhatsappIcon
        href="https://wa.me/+40723769462"
        className="d-flex"
        style={{ color: "#25D366" }}
        target="_blank"
      >
        <img
          src="/images/social-media-icons/whatsapp.png"
          style={{ height: "25px", width: "25px" }}
        />
      </WhatsappIcon>
    </SocialMediaContainer>
  );
};

export default SocialMediaBar;
