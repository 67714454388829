import React from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import Radium, { StyleRoot } from "radium";
import { Container, Row } from "react-bootstrap";

const HomeHeader = styled(Container)`
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const HomeText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
  padding: 5px;
  text-align: center;
`;
const HomePictureWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextBigScreen = styled.div`
  width: 80%;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
const TextSmallScreen = styled.div`
  padding: 30px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

const NavLink = styled(Link)``;
const Logo = styled.div``;
const CipriPicture = styled.div``;

const style = {
  LogoImage: {
    height: "150px",
    "@media (min-width: 1000px)": {
      width: "150px",
      height: "150px",
    },
    "@media (max-width: 999px)": {
      width: "100px",
      height: "100px",
    },
  },

  CipriPicture: {
    height: "150px",
    "@media (min-width: 1000px)": {
      width: "150px",
      height: "150px",
    },
    "@media (max-width: 999px)": {
      width: "100px",
      height: "100px",
    },
  },

  HomePicture: {
    width: "60%",
    "@media (max-width: 800px)": {
      width: "100%",
    },
  },
};
const Home = () => {
  return (
    <StyleRoot>
      {/* ---------------------------------Header-ul------------------------ */}
      <HomeHeader>
        <Logo>
          <NavLink to="/home">
            <img src="/images/basc-logo.jpg" style={style.LogoImage} />
          </NavLink>
        </Logo>
        <HomeText>
          <Title>Birou de arhitectură Șimon Ciprian</Title>
          <TextBigScreen>
            <div>
              Cred că arhitectura și urbanismul vor avea mereu la bază cele trei
              vechi principii, utilitate, trăinicie și frumusețe.
            </div>
            <div>
              Ponderea lor, în măsuri variabile, este rezultatul colaborării
              dintre arhitect, beneficiar și comunitatea în care trăiesc.
            </div>
            <div>
              Toată munca arhitectului trebuie îndreptată către găsirea
              echilibrului între util, trainic și frumos , pentru a ni se
              descoperi nouă tuturor, cât mai multe din bucuriile esențiale ale
              vieții.
            </div>
          </TextBigScreen>
        </HomeText>
        <CipriPicture>
          <img src="/images/poza-Cipri.jpg" style={style.CipriPicture} />
        </CipriPicture>
      </HomeHeader>
      <TextSmallScreen>
        <div>
          Cred că arhitectura și urbanismul vor avea mereu la bază cele trei
          vechi principii,utilitate, trăinicie și frumusețe.
        </div>
        <div>
          Ponderea lor, în măsuri variabile, este rezultatul colaborării dintre
          arhitect, beneficiar și comunitatea în care trăiesc.
        </div>
        <div>
          Toată munca arhitectului trebuie îndreptată către găsirea echilibrului
          între util, trainic și frumos , pentru a ni se descoperi nouă tuturor,
          cât mai multe din bucuriile esențiale ale vieții.
        </div>
      </TextSmallScreen>
      {/* ------------------------Home Image------------------------ */}
      <HomePictureWrap>
        <img src="/images/home-picture.jpg" style={style.HomePicture} />
      </HomePictureWrap>
    </StyleRoot>
  );
};

export default Home;
