import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import MenuItemComponent from "./MenuItemComponent";
import Certificari from "../Pages/Certificari";
import CardDetails from "./CardDetails";

const Views = ({
  categoryData,
  cardsData,
  imagesData,
  //currentCard,
  findCurrentCard,
  findCurrentCardGalleryImages,
  //currentCardGalleryImages,
}) => {
  //console.log(cardsData);
  return (
    <>
      {categoryData.length != 0 &&
        cardsData.length != 0 &&
        imagesData.length != 0 && (
          <Routes>
            <Route path="/" element={<Home />} />

            <Route
              path="/Certificari"
              element={<Certificari imagesData={imagesData} />}
            />

            {categoryData.map((item) => {
              return (
                <Route
                  key={item.Id}
                  path={item.Path}
                  element={
                    <MenuItemComponent
                      category={item}
                      cardsData={cardsData}
                      imagesData={imagesData}
                    />
                  }
                ></Route>
              );
            })}

            {categoryData.map((item) => {
              return (
                <Route
                  key={item.Id}
                  path={`${item.Path}/:CardId`}
                  element={
                    <CardDetails
                      cardsData={cardsData}
                      imagesData={imagesData}
                      findCurrentCard={findCurrentCard}
                      currentCategory={item}
                      findCurrentCardGalleryImages={
                        findCurrentCardGalleryImages
                      }
                    />
                  }
                />
              );
            })}

            {/* {categoryData.map((item) => {
            return (
              <Route key={item.Id} path={item.Path}>
                <Route
                  index
                  element={
                    <MenuItemComponent
                      category={item}
                      cardsData={cardsData}
                      imagesData={imagesData}
                    />
                  }
                />
                <Route
                  path={`${item.Path}/:CardId`}
                  element={
                    <CardDetails
                      cardsData={cardsData}
                      imagesData={imagesData}
                      findCurrentCard={findCurrentCard}
                      currentCategory={item}
                      findCurrentCardGalleryImages={
                        findCurrentCardGalleryImages
                      }
                    />
                  }
                />
              </Route>
            );
          })} */}
          </Routes>
        )}
    </>
  );
};

export default Views;
