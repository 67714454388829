import React, { useState, useEffect } from "react";
import SingleCard from "./SingleCard";
import { Container, Row, Col } from "react-bootstrap";
import ComponentHeader from "./ComponentHeader";
import ImageGallery from "./ImageGallery";

const MenuItemComponent = ({ cardsData, category, imagesData }) => {
  const [currentCards, setCurrentCards] = useState([]);
  const [allImagesWithoutCards, setAllImagesWithoutCards] = useState([]);
  const [imagesWithoutCardsByCategory, setImagesWithoutCardsByCategory] =
    useState([]);

  useEffect(() => {
    if (cardsData && category) {
      const currCards = cardsData.filter((el) => {
        return parseInt(el.CategoryId) == parseInt(category.Id);
      });

      setCurrentCards(currCards);
    }
  }, [cardsData]);

  useEffect(() => {
    if (cardsData && category) {
      const currCards = cardsData.filter((el) => {
        return parseInt(el.CategoryId) == parseInt(category.Id);
      });

      setCurrentCards(currCards);
    }
  }, [cardsData]);

  useEffect(() => {
    if (imagesData) {
      const allImgWithoutCards = imagesData.filter((item) => {
        return item.CardId == 0;
      });

      setAllImagesWithoutCards(allImgWithoutCards);
    }
  }, [imagesData]);

  useEffect(() => {
    if (allImagesWithoutCards && category) {
      const imgWithoutCardsByCategory = allImagesWithoutCards.filter((item) => {
        return item.CategoryId == category.Id;
      });

      setImagesWithoutCardsByCategory(imgWithoutCardsByCategory);
    }
  }, [allImagesWithoutCards, category]);

  const componentStyle = {
    containerWrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
    },
    galleryWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "50px",
    },
    cardsContainer: {
      width: "100%",
    },
  };
  return (
    <>
      {currentCards && currentCards.length > 0 && (
        <Container style={componentStyle.containerWrap}>
          <ComponentHeader category={category} />
          <Container style={componentStyle.galleryWrapper}>
            <Container style={componentStyle.cardsContainer}>
              <Row xs="auto" className="justify-content-center">
                {currentCards &&
                  currentCards.map((item) => {
                    return (
                      <Col key={item.Id}>
                        <SingleCard item={item} currentCategory={category} />
                      </Col>
                    );
                  })}
              </Row>
            </Container>
          </Container>
        </Container>
      )}

      {currentCards && currentCards.length == 0 && (
        <>
          <Container style={componentStyle.containerWrap}>
            <ComponentHeader category={category} />
          </Container>
          <ImageGallery galleryImages={imagesWithoutCardsByCategory} />
        </>
      )}
    </>
  );
};

export default MenuItemComponent;
